var FRAMEWORK = window.FRAMEWORK || {};

FRAMEWORK.requireInitConfig = {
	baseUrl : '/js',
	integrityDigests : JSON.parse( '{"main":"sha384-PKSH35mCKUWN2vU+mQkoMTDwSkE0zb8p3SR0Y0Axx8qpuGXHHhB/xUy4ooj/i+rR","vendor/core/require":"sha384-sREwwTrLSeZ2UQ+JObY7z3sndwRyc+EhSgQ9h5vXGPrtQhNCdfFSI0TWsTf2ZELD","stand-alone-main":"sha384-0mKe063ubhEKMsZvM4DFViVuXgbkh7m59oWh3AzC2Wihca2BQpL9X0IAmJC907Me"}' ),
	moduleAttributes : {},
};

// Extend require config
( function fn() {
	'use strict';

	var initScript,
		currentUrl,
		head,
		script,
		customRequireConfigUrl,
		customRequireConfigSri,
		loadMain = function() {
			script = document.createElement( 'script' );
			script.src = FRAMEWORK.requireInitConfig.baseUrl + '/main.js';
			script.setAttribute( 'integrity', FRAMEWORK.requireInitConfig.integrityDigests.main ); // Sets the integrity attribute to the require element
			script.setAttribute( 'crossorigin', 'anonymous' );
			head.appendChild( script );
		};

	/*
	 * Check browser has minimum level of feature support
	 * Bar set at IE11 using techniques covered https://walterebert.com/blog/gracefully-ignoring-old-browsers/
	 */

	if ( 'Intl' in window ) {
		// Set base URL based on where init.js is being loaded from
		initScript = document.getElementById( 'js-init' );
		if ( initScript !== null ) {
			currentUrl = initScript.src;

			FRAMEWORK.requireInitConfig.baseUrl = currentUrl.replace( '/init.js', '' );

			customRequireConfigUrl = initScript.getAttribute( 'data-require' );
			customRequireConfigSri = initScript.getAttribute( 'data-require-integrity' );
			if ( customRequireConfigUrl ) {
				FRAMEWORK.requireInitConfig.customRequireConfigUrl = customRequireConfigUrl;
				FRAMEWORK.requireInitConfig.moduleAttributes[ customRequireConfigUrl ] = { crossorigin : 'anonymous' };
				if ( customRequireConfigSri ) {
					FRAMEWORK.requireInitConfig.moduleAttributes[ customRequireConfigUrl ].integrity = customRequireConfigSri;
				}
			}

			FRAMEWORK.requireInitConfig.localeUrl = initScript.getAttribute( 'data-locale' );
			FRAMEWORK.requireInitConfig.settingsUrl = initScript.getAttribute( 'data-settings' );

			if ( FRAMEWORK.requireInitConfig.localeUrl !== null ) {
				FRAMEWORK.requireInitConfig.moduleAttributes[ FRAMEWORK.requireInitConfig.localeUrl ] = { crossorigin : 'anonymous' };
			}
			if ( FRAMEWORK.requireInitConfig.settingsUrl !== null ) {
				FRAMEWORK.requireInitConfig.moduleAttributes[ FRAMEWORK.requireInitConfig.settingsUrl ] = { crossorigin : 'anonymous' };
			}

			FRAMEWORK.requireInitConfig.appUrl = initScript.getAttribute( 'data-app' );
		}

		// Insert RequireJS in page
		head = document.getElementsByTagName( 'head' )[ 0 ];

		if ( typeof require === 'function' ) {
			// If require is already loaded then don't load it again. Just load main and intitialise Framework
			loadMain();
		} else {
			script = document.createElement( 'script' );
			script.src = FRAMEWORK.requireInitConfig.baseUrl + '/vendor/core/require.js';
			// If running a karma test then load main.js using the data-main attribute
			if ( FRAMEWORK.karma ) {
				script.setAttribute( 'data-main', FRAMEWORK.requireInitConfig.baseUrl + '/main.js' );
			}
			script.setAttribute( 'integrity', FRAMEWORK.requireInitConfig.integrityDigests[ 'vendor/core/require' ] ); // Sets the integrity attribute to the require element
			script.setAttribute( 'crossorigin', 'anonymous' );
			head.appendChild( script );

			/*
			 * If not running a karma test then load main.js after require.js script has
			 * loaded so that crossorigin and integrity can be applied to it
			 */
			if ( !FRAMEWORK.karma ) {
				script.onload = function onload() {
					loadMain();
				};
			}
		}
	}
})();
